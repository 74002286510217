<template>
	<div class="main-content">
		<div class="flex flex-col lg:flex-row gap-4">
			<div class="col-12 col-lg-7 py-4 px-2 md:px-4 d-flex flex-column gap-4 position-relative">
				<div class="d-flex flex-col  md:justify-between lg:flex-col gap-2">
					<div class="flex justify-between items-center">
						<button @click="filter = true" class="rounded border px-2 py-1 bg-white d-flex gap-6 align-items-center justify-content-between w-fit">
							Filter
							<img src="@/assets/img/icons/plus_black.svg" alt="">
						</button>
						<div class="flex items-center gap-2">
							<label class="switch">
								<input v-model="filterChecked" :disabled='filterLoading' type="checkbox" />
								<span class="slider round"></span>
							</label>
							<p class="font-weight-bold ml-2 mt-2">
								View staff audit only
							</p>

						</div>
					</div>


					<div class="flex  gap-4 items-center justify-between">
						<v-datepicker v-model="range" placeholder="Filter by date" range class="min-w-[100px] max-w-fit"></v-datepicker>
						<button @click="downloadReport" v-if="!downloadLoader" class="d-flex align-items-center gap-2 bg-transparent border-0 font-weight-bold">
							<img src="@/assets/img/icons/download_icon.svg" alt="">
							<span>Download Report</span>
						</button>
						<div v-else class="spinner-border mr-[20px]"></div>
					</div>
				</div>
				<div class="d-flex gap-4 flex-wrap">
					<p style="background: #EFF2F7;" v-for="n, index in filterTypeArr" :key="index" class="d-flex gap-2 align-items-center text-xs rounded py-1 px-2">
						{{ n }}
						<img style="width: 15px; cursor: pointer;" @click="adjustTypeFilter(n.toLowerCase())" class="cursor-pointer" src="@/assets/img/close-icon.svg" />
					</p>
				</div>

				<div class="d-flex gap-4 flex-wrap" v-if="filterByActor.length">
					<p style="background: #EFF2F7;" v-for="n, index in filterActorArr" :key="index" class="d-flex gap-2 align-items-center rounded py-1 px-2 text-xs">
						{{ findActorNameWithId(n) }}
						<img style="width: 15px; cursor: pointer;" @click="adjustActorFilter(n)" class="cursor-pointer" src="@/assets/img/close-icon.svg" />
					</p>
				</div>

				<div ref="target">
					<div v-if="filter" class="position-absolute filter_pos bg-white p-2 border rounded d-flex flex-column">
						<button class="filter text-left bg-transparent p-2 border-0" @click="openTypeFilter">Type</button>
						<button class="filter text-left bg-transparent p-2 border-0" @click="openActorFilter">Actor</button>
					</div>

					<div v-if="typeStatus" class="position-absolute bg-white type_pos p-2 border rounded d-flex flex-column">
						<div class="d-flex align-items-center p-2 gap-2 w-fit" v-for="n in filterByType" :key="n">
							<input type="checkbox" :id="n" :checked="filterTypeArr.includes(n.toLowerCase())" @change="adjustTypeFilter(n.toLowerCase())">
							<label :for="n" class="text-sm m-0 text-primary cursor-pointer">{{ n }}</label>
						</div>
					</div>
					<div v-if="actorStatus" class="bg-white position-absolute type_pos border rounded d-flex flex-column ">
						<div class="p-3 border-bottom ">
							<input type="text" class="w-100 border-0 outline-none" v-model="searchText" placeholder="Search...">
						</div>
						<div class="p-3 bg-white">
							<template v-for="n, index in filteredAdmin">
								<div class="d-flex align-items-center p-2 gap-2 " :key="index" v-if="index < 7">
									<input type="checkbox" :id="n" :checked="actorSelected.includes(n.id)" @change="adjustActorFilter(n.id)">
									<label :for="n" class="text-sm m-0 text-primary cursor-pointer">{{ `${n.fname} ${n.lname}` }}</label>
								</div>
							</template>
						</div>

					</div>
				</div>

				<div class="bg-white border rounded ">
					<b-table striped hover selectable show-empty responsive @row-clicked="showDataDetails" :items="tableData" :fields="activityFields" :busy="loading">
						<template #table-busy>
							<div class=" d-flex flex-column justify-content-center align-items-center">
								<div class="spinner-border" role="status"></div>
								<p class="text-center mt-2"><strong>Loading...</strong></p>
							</div>
						</template>
						<template #empty>
							<p class="text-center text-secondary">No records available</p>
						</template>
						<template #cell(createdAt)="data">
							<div class="relative min-h-[50px]">
								<div v-if="moment().diff(moment(data.item.createdAt), 'hours') > 24" class="flex flex-col">
									<p class="text-black font-bold whitespace-nowrap">{{ moment(data.item.createdAt).format("Do MMM, YYYY") }}</p>
									<p>{{ moment(data.item.createdAt).format("h:mm A") }}</p>
								</div>
								<p v-else class="text-black whitespace-nowrap font-weight-bold">{{ moment(data.item.createdAt).fromNow() }}</p>
								<div class="absolute top-0 -left-3 h-[50px] w-[4px] bg-[#20E682]" v-if="details && data.item.id == details.id"></div>
							</div>
						</template>
						<template #cell(description)="data">
							<div class="bg-transparent d-flex align-items-center gap-4 w-full border-2">
								<span class="light-color">{{ data.item.description ? data.item.description : 'No Description' }}</span>
								<img src="@/assets/img/icons/arrow_right.svg" alt="">
							</div>
						</template>
					</b-table>
				</div>

				<div class="card-footer" v-if="totalRecords">
					<div class="row align-items-center">
						<!-- <div class="col">
							<span class="text-dark font-weight-bold">{{
								tablePageText
							}}</span>
						</div> -->
						<div class="col-auto">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="pageSize"></b-pagination>
						</div>
					</div>
				</div>
			</div>
			<div class="bg-white h-fit pb-20 d-flex flex-column gap-4 col-12 col-lg-5">
				<div class="py-4 px-2 d-flex flex-col gap-0 border-bottom">
					<h2 class="font-weight-medium m-0">About this activity</h2>
					<p class="m-0">Full glance of all actions for this activity</p>
				</div>

				<div v-if="!details" class="p-4 d-flex align-items-center justify-content-center">
					<div class="d-flex flex-column gap-0">
						<img class="w-[60%] mx-auto" src="@/assets/img/illustrations/empty_activity.svg" alt="" />
						<h3 class="text-md text-center">No Activity</h3>
						<p class="text-sm text-center">No activity Kindly select an activity to view full details about it
						</p>
					</div>
				</div>
				<div v-else class="flex flex-col gap-8">
					<div class="grid grid-cols-2 gap-x-4 gap-y-8 justify-between">
						<div class="flex flex-col">
							<p class="text-[12px] text-[#444854]">Actor</p>
							<p class="text-sm font-bold text-black">{{ details.fname }} {{ details.lname }}</p>
						</div>
						<div class="flex flex-col">
							<p class="text-[12px] text-[#444854]">Email Address</p>
							<p class="text-sm font-bold text-black">{{ details.userEmail }}</p>
						</div>
						<div class="flex flex-col">
							<p class="text-[12px] text-[#444854]">IP Address</p>
							<p class="text-sm font-bold text-black">{{ details.ipAddress ?? 'N/A' }}</p>
						</div>
						<div class="flex flex-col">
							<p class="text-[12px] text-[#444854]">Date & Time</p>
							<p class="text-sm font-bold text-black">{{ moment(details.createdAt).format("Do MMMM, YYYY h:mm A") }}</p>
						</div>
					</div>
					<div class="flex flex-col">
						<p class="text-[12px] text-[#444854]">Action</p>
						<p class="text-sm font-bold text-black">{{ details.description ?? 'N/A' }}</p>
					</div>
					<div class="flex flex-col">
						<p class="text-[12px] text-[#444854]">Type</p>
						<p class="text-sm font-bold text-[#4848ED]">{{ details.levelOfImportance ?? 'N/A' }}</p>
					</div>
					<!-- <div class="border rounded p-2 px-4 flex items-center justify-between">
						<p class="text-sm text-black font-bold">20+ associated activities</p>
						<button class="text-[#4848ED] border-0 bg-transparent text-sm font-medium">See all</button>
					</div> -->
					<div v-if="details.auditType === 'UPDATE'" class="flex flex-col">
						<p class="text-[12px] text-[#444854]">Updated data</p>
						<!-- <p class="text-sm font-bold text-[#4848ED]">{{ details.levelOfImportance ?? 'N/A' }}</p> -->
						<div class="w-full overflow-auto">
							<table class="min-w-[400px]">
								<thead>
									<tr class="border-bottom">
										<th class="p-2">Property</th>
										<th class="p-2">Old value</th>
										<th class="p-2">New value</th>
									</tr>
								</thead>
								<tr v-for="n in all_new_data_keys" :key="n" class="border-bottom">
									<td class="p-2">{{ n }}</td>
									<td class="p-2">
										{{ oldData[n] }}
									</td>
									<td class="p-2">
										{{ newData[n] }}
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { onClickOutside } from '@vueuse/core'
import { addToQuery, removeQueryParam, formatApiCallDate, formatDate, downloadFile } from '@/composables/utils.js'
import router from '@/router'
import { useRoute } from 'vue-router/composables'
import moment from 'moment'
import { axiosInstance as axios } from '@/plugins/axios.js'
import Papa from 'papaparse'
import Swal from 'sweetalert2'


const totalRecords = ref(null)
const currentPage = ref(1)
const pageSize = ref(10)
const route = useRoute()
const target = ref(null)
const filter = ref(false)
const typeStatus = ref(false)
const actorStatus = ref(false)
const range = ref([])
const activityFields = [
	{ label: 'Time', key: 'createdAt' },
	{ label: 'Activity', key: 'description' },
]
const filterByType = ['Normal', 'Important', 'Critical']
const filterByActor = ref([])
const filterTypeArr = ref([])
const filterActorArr = ref([])
const tableData = ref([])
const loading = ref(false)
const details = ref(null)
const searchText = ref('')
const downloadLoader = ref(false)
const filterLoading = ref(false)
const filterChecked = ref(false)
const oldData = ref(null)
const newData = ref(null)




const closeAllFilterBox = () =>
{
	filter.value = false
	typeStatus.value = false
	actorStatus.value = false
}
const openTypeFilter = () =>
{
	actorStatus.value = false
	typeStatus.value = true
}
const openActorFilter = () =>
{
	typeStatus.value = false
	actorStatus.value = true
}
onClickOutside(target, () =>
{
	closeAllFilterBox()
})
const adjustTypeFilter = (str) =>
{
	if (!filterTypeArr.value.includes(str)) {
		filterTypeArr.value.push(str)
	} else {
		const index = filterTypeArr.value.indexOf(str)
		filterTypeArr.value.splice(index, 1)
	}
	if (filterTypeArr.value.length) {
		addToQuery(route, router, { type: filterTypeArr.value.join(',') })
	} else {
		removeQueryParam(route, router, ['type'])
	}
	fetchData()
}
const adjustActorFilter = (id) =>
{
	if (!filterActorArr.value.includes(id)) {
		filterActorArr.value.push(id)
	} else {
		const index = filterActorArr.value.indexOf(id)
		filterActorArr.value.splice(index, 1)
	}
	if (filterActorArr.value.length) {
		addToQuery(route, router, { actor: filterActorArr.value.join(',') })
	} else {
		removeQueryParam(route, router, ['actor'])
	}
	fetchData()
}
const findActorNameWithId = (id) =>
{
	const x = filterByActor.value.find((obj) => obj.id === id)
	return x ? `${x.fname} ${x.lname}` : ''
}
watch(range, () =>
{
	if (formatDate(range.value[0]) == route.query.start && formatDate(range.value[1]) == route.query.end) return
	// the above is necessary cos vue router 3 is complaining navigation to current page 
	// which is triggerd by either addToQuery or RemveQuery below
	if (range.value[0]) {
		addToQuery(route, router, { start: formatDate(range.value[0]), end: formatDate(range.value[1]) })
	} else {
		removeQueryParam(route, router, ['start', 'end'])
	}
	fetchData()
})

watch(currentPage, (newVal, oldVal) =>
{
	if (newVal != oldVal && currentPage.value > 1) {
		addToQuery(route, router, { page: currentPage.value })
	} else {
		removeQueryParam(route, router, ['page'])
	}
	fetchData()
})
const returnToDateObject = (str) =>
{
	return moment(str, 'DD-MM-YYYY').toDate()
}
const fetchFilterCheck = async ()=>{
	const res = await axios.get('/audit/v1/feature-flag?name=get-all-audits')
	filterChecked.value = res.data.active
}
const fetchData = () =>
{
	loading.value = true
	fetchFilterCheck()
	const res = axios.get(`/audit/v1/audits?metadata=true&page=${currentPage.value}&perPage=10${filterTypeArr.value.length ? `&levelOfImportance=${filterTypeArr.value.join(',')}` : ''}${filterActorArr.value.length ? `&actors=${filterActorArr.value.join(',')}` : ''}${range.value[0] ? `&startDate=${formatApiCallDate(range.value[0])}` : ''}${range.value[1] ? `&endDate=${formatApiCallDate(range.value[1])}` : ''}`)
		.then((data) =>
		{
			tableData.value = data.data.result
			totalRecords.value = data.data.metadata.total
		})
		.catch((err) =>
		{
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: err?.response?.data?.message || 'An error occured',
				showCloseButton: true
			})
		})
		.finally(() => (loading.value = false))
}
const fetchAllAdmins = () =>
{
	const res = axios.get('/v1/staff?limit=10000')
		.then((data) =>
		{
			const x = data.data.data
			x.length ? filterByActor.value = x : filterByActor.value = []
			const actor = route.query.actor
			if (actor) {
				const y = actor.split(',')
				removeQueryParam(route, router, ['actor'])
				for (let i = 0; i < y.length; i++) {
					const el = y[i]
					filterActorArr.value.push(Number(el))
				}
				addToQuery(route, router, { actor: filterActorArr.value.join(',') })
				fetchData()
			}
		})
		.catch((err) =>
		{
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: err?.response?.data?.message || 'An error occured',
				showCloseButton: true
			})
		})
}
const downloadReport = async () =>
{
	downloadLoader.value = true
	axios
		.get(`/audit/v1/audits?metadata=true&perPage=10${filterTypeArr.value.length ? `&levelOfImportance=${filterTypeArr.value.join(',')}` : ''}${filterActorArr.value.length ? `&actors=${filterActorArr.value.join(',')}` : ''}${range.value[0] ? `&startDate=${formatApiCallDate(range.value[0])}` : ''}${range.value[1] ? `&endDate=${formatApiCallDate(range.value[1])}` : ''}`)
		.then(async (resp) =>
		{
			const res = await axios.get(`/audit/v1/audits?metadata=true&perPage=${resp.data.metadata.total}${filterTypeArr.value.length ? `&levelOfImportance=${filterTypeArr.value.join(',')}` : ''}${filterActorArr.value.length ? `&actors=${filterActorArr.value.join(',')}` : ''}${range.value[0] ? `&startDate=${formatApiCallDate(range.value[0])}` : ''}${range.value[1] ? `&endDate=${formatApiCallDate(range.value[1])}` : ''}`)
			if (res.data.result.length) {
				const x = res.data.result
				const newArr = []
				for (let i = 0; i < x.length; i++) {
					const el = x[i]
					const y = {
						Actor: `${el.fname} ${el.lname}`,
						Email: el.userEmail,
						Ip_Address: el?.ipAddress,
						Date: moment(el.createdAt).format('Do MMM, YYYY h:mm A'),
						Action: el.description ? el.description : 'No Description',
						Type: el.levelOfImportance
					}
					newArr.push(y)
				}
				const csv = Papa.unparse(newArr)
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
				const url = URL.createObjectURL(blob)
				downloadFile(url, 'audit-trail-report')
			} else {
				Swal.fire({
					icon: 'error',
					title: 'Error',
					text: 'No data to download',
					showCloseButton: true
				})
			}
		})
		.catch((err) =>
		{
			Swal.fire({
				icon: 'error',
				title: 'Error',
				text: err?.response?.data?.message || 'An error occured',
				showCloseButton: true
			})
		})
		.finally(() =>
		{
			downloadLoader.value = false
		})
}

watch(filterChecked, async (val)=>{
	filterLoading.value = true
	try {
		filterChecked.value = val
		await axios.post('/audit/v1/feature-flag', { 'name': 'get-all-audits', 'active': val })
			fetchData()
	fetchAllAdmins()

	}catch (e) {

	}finally {
		filterLoading.value = false
	}
})
const clearUpdatedData = () => {
	oldData.value = null
	newData.value = null
}
const showDataDetails = (data) =>
{
	clearUpdatedData()
	if (data.auditType === 'UPDATE') handleDataChanges(data)
	details.value = data
	window.scrollTo({ top: 0, behavior: 'smooth' })
}

function isObjectNotEmpty(obj) {
  return Object.keys(obj).length > 0
}

function flattenObject(obj) {
  const result = {}

  function recurse(current) {
    for (const key in current) {
      if (current[key] && typeof current[key] === 'object') {
        recurse(current[key])
      } else {
        result[key] = current[key]
      }
    }
  }

  recurse(obj)
  return result
}
const handleDataChanges = (data) => {
	if (isObjectNotEmpty(data?.oldData)) {
		oldData.value = flattenObject(data?.oldData)
	}
	if (isObjectNotEmpty(data?.newData)) {
		newData.value = flattenObject(data?.newData)
	}
}

// computed
const actorSelected = computed(() =>
{
	return filterActorArr.value.map((item) => item)
})

const filteredAdmin = computed(() =>
{
	return filterByActor.value.filter((obj) =>
		obj.fname.toLowerCase().includes(searchText.value.toLowerCase()) ||
		obj.lname.toLowerCase().includes(searchText.value.toLowerCase()))
})

const all_new_data_keys = computed(() => {
	if (newData.value) return Object.keys(newData.value)
	return []
})

onMounted(() =>
{
	const type = route.query.type
	// const actor = route.query.actor
	const start = route.query.start
	const end = route.query.end
	const page = route.query.page
	if (page) currentPage.value = Number(page)
	if (type) filterTypeArr.value = type.split(',')
	// if (actor) filterActorArr.value = actor.split(',')
	if (start && end) {
		range.value = [returnToDateObject(start), returnToDateObject(end)]
	} else if (start && !end) {
		removeQueryParam(route, router, ['start'])
	} else if (!start && end) {
		removeQueryParam(route, router, ['end'])
	}
	fetchData()
	fetchAllAdmins()
})

</script>

<style scoped>
.light-color {
	color: #444854;
}

h3,
p {
	margin: 0 !important;
}

.filter_pos {
	top: 60px;
	left: 25px;
	min-width: 150px;
}

.type_pos {
	top: 70px;
	left: 180px;
	min-width: 150px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 45px;
    height: 25px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 24px;
    width: 24px;
    left: 0px;
    bottom: 0px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #15CD70;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #15CD70;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
</style>
